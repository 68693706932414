<template>
  <ig-page-base
    :is-boxed="true"
    :title="$t('pageRegisterSuccess.successMessage')"
    class="page-success">
    <div>
      <b-message type="is-success">
        <p
          class="field"
          v-html="$t('pageRegisterSuccess.tutorialMessage')" />
      </b-message>

      <div class="field">
        <p class="is-size-7 has-text-centered">
          <a
            :href="manageUrl"
            class="button is-text is-link is-primary has-text-weight-bold">
            {{ $t('pageRegisterSuccess.link') }}
          </a>
        </p>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { IgPageBase } from '@integrai/ui';

export default Vue.extend({
  name: 'page-success',
  components: {
    IgPageBase,
  },
  computed: {
    manageUrl() {
      return process.env.VUE_APP_MANAGE_URL;
    },
  },
});
</script>

<style lang="scss">
.page-success {
  width: 100%;

  .box.ig-box {
    max-width: 500px;
  }

  &__buttons {
    margin-top: 1.5rem;
    font-weight: bold;
  }

  &__tutorial {
    margin: 2rem 0;
  }
}
</style>
